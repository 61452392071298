.siteloader {
    /* background: transparent linear-gradient(117deg, #003D78 0%, #F9423A 100%) 0% 0% no-repeat padding-box; */
    background: radial-gradient(circle, rgba(202,163,119,1) 0%, rgba(172,134,91,1) 100%);
    position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;


}
.main{
    position: absolute;
    top:35%;
    left:50% ;
    transform: translate(-50%,-50%);
}

.center{
    position: absolute;
    margin: 50.5px;
    margin-top: 69px;
}


.middle{
    position: absolute;
    padding: -11px;
    margin: 24px;
    margin-top: 46px;
}

.top{
    position: absolute;
}